<template>
  <page-view :title="pageTitle" left-arrow :nav-border="false" custom-class="page-sbjlcx">
    <template #nav-right>
      <yhbh-select v-model="form.yhbh" @confirm="yhbhChangeHandle" :custom-style="{color: '#fff'}"/>
    </template>
      <loading-layout ref="loadingLayoutOuter"  error-text="绑定户号信息查询失败" empty-text="未绑定用户编号">
          <template #empty>
              <yhbh-empty fromPage="sbjlcx"/>
          </template>
          <loading-layout ref="loadingLayout" :error-text="pageErrortext">
              <template #empty>
                  <van-empty :description="pageErrortext" :image="require('@/assets/images/load-error.png')">
                      <van-button type="primary" class="bottom-button" @click="pageBack" size="small" style="padding: 4px 20px;">返回</van-button>
                  </van-empty>
              </template>

              <div class="top">
                <div class="yh-zhxx">
                  <dl>
                    <dt>用户编号：</dt>
                    <dd>{{yhJbxx.yhbh}}</dd>
                  </dl>
                  <dl>
                    <dt>用户名称：</dt>
                    <dd>{{yhJbxx.yhmc}}</dd>
                  </dl>
                  <dl>
                    <dt>用户地址：</dt>
                    <dd>{{yhJbxx.yhdz}}</dd>
                  </dl>
                  <dl>
                    <dt>当前结余：</dt>
                    <dd>{{yhJbxx.dqjy | currency}}</dd>
                  </dl>
                  <dl>
                    <dt>当前欠费：</dt>
                    <dd :style="{display: 'inline-flex', alignItems: 'center'}">
                      <span style="display: inline-block;">{{yhJbxx.dqqf | currency}}</span>
                      <van-button 
                        v-if="yhJbxx.dqqf > 0"
                        type="warning" 
                        size="small" 
                        :style="{
                          padding: '0 12px',
                          height: '26px',
                          borderRadius: '3px',
                          marginLeft: '10px'
                        }"
                        :to="'/dfcj/'+yhJbxx.yhbh"
                      >
                        缴纳欠费
                      </van-button>
                    </dd>
                  </dl>
                </div>
              </div>

              <div class="sbjl-list container">
                <div class="sbjl-item" v-for="(item,index) in sbjhmxList" :key="index">
                  <span class="yhzd-item__zwyf">{{item.jhyf}}</span>
                  <van-cell-group :border="false">
                    <van-cell :border="false" title="状态:" :value="item.dqzt"/>
                    <van-cell :border="false" title="基本费计算方式:" :value="item.jbflb"/>
                    <van-cell :border="false" title="计划电量:" :value="item.jhdl"/>
                  </van-cell-group>
                </div>
              </div>
          </loading-layout>
      </loading-layout>
  </page-view>
</template>

<script>
    import { Uploader } from 'vant';
    import axios from 'axios';
    import { mapGetters } from 'vuex';
    import VantSelect from '@/components/VantSelect/index.vue'

    import YhbhEmpty from '@/components/YhbhEmpty/index.vue';
    import YhbhSelect from '@/components/YhbhSelect/index.vue';

    import { scsdService_getSbdljl } from '@/api/psdmsschjy/service/ScsdServiceAPI';

export default {
    name: 'sbjlcx',
    components: {
        [Uploader.name]: Uploader,
        VantSelect,
        YhbhEmpty,
        YhbhSelect
    },
    data() {
        return {
            pageTitle: "申报记录查询",
            pageErrortext: '',
            yhbh: null,
            yhJbxx: {},
            sbjhmxList: [],
            sbjhxx:{},
            sbjhmx: {},
            form: {
                yhbh: null,
                yhmc: '',
                yhbz: '9',
                jhyf: null,
                jbflb: '',
                jhdl: 0
            },
            files: []
        }
    },
    computed: {
        ...mapGetters(['jgbm','dybm','czyh','dlzh','yhid','yhsj']),
    },
    methods: {
        pageBack() {
            this.$router.go(-1)
        },
        yhbhChangeHandle(yhxx) {
            this.yhbh = yhxx.yhbh;
            this.pageSetup();
        },
        pageSetup() {
            this.$refs['loadingLayout'].setStatus('loading');
            scsdService_getSbdljl(this.yhbh).then(res => {
                if (res.content.status == 200) {
                    // this.sbjhxx = res.content.data.scsdDlsbjh;
                    this.sbjhmxList = res.content.data;
                    this.sbjhmx = this.sbjhmxList[0];
                    this.form = {
                        yhbh: this.sbjhmx.yhbh,
                        yhmc: this.sbjhmx.yhmc,
                        jhyf: this.sbjhmx.jhyf,
                        jbflb: this.sbjhmx.jbflb,
                        jhdl: this.sbjhmx.jhdl || 0
                    }

                    for (var i = 0; i < this.sbjhmxList.length; i++) {
                        //转换代码内容
                        switch(this.sbjhmxList[i].dqzt) {
                            case '-2':
                                this.sbjhmxList[i].dqzt = '待上报'
                                break
                            case '-1':
                                this.sbjhmxList[i].dqzt = '已上报待确认'
                                break
                            case '0':
                                this.sbjhmxList[i].dqzt = '已完成'
                                break
                            case '1':
                                this.sbjhmxList[i].dqzt = '审核不通过'
                                break
                            case '2':
                                this.sbjhmxList[i].dqzt =  '作废'
                                break
                        }

                        switch(this.sbjhmxList[i].jbflb) {
                            case '0':
                                this.sbjhmxList[i].jbflb = '未申报'
                                break
                            case '1':
                                this.sbjhmxList[i].jbflb = '按容量计'
                                break
                            case '2':
                                this.sbjhmxList[i].jbflb =  '按需量计'
                                break
                        }

                        //转换计划月份
                        this.sbjhmxList[i].jhyf = Math.floor(this.sbjhmxList[i].jhyf / 100) + '年' + this.sbjhmxList[i].jhyf % 100 + '月'
                    }
                    this.$refs['loadingLayout'].setStatus('success');
                } else {
                    this.pageErrortext = res.content.message;
                    this.$refs['loadingLayout'].setStatus('empty');
                }
            }).catch(err => {
                this.pageErrortext = '查询市场化售电申报电量记录失败';
                this.$refs['loadingLayout'].setStatus('net-error');
            });
        },
        onFailed({
            values,
            errors
        }) {
            console.log('failed:, Errors:', errors)
            this.$notify({
                type: 'danger',
                message: errors[0].message
            });
        },
        afterRead(file) {
            console.log(file);
            const formData = new FormData();
            formData.append(file, file.file);
            axios.post('/', formData,{
                headers: {
                    "content-type": "multer/form-data"
                }
            }).then(res => {
                console.log(res.data);
            })
        },
    },
    mounted() {
        this.$refs['loadingLayoutOuter'].setStatus('loading');
        this.$store.dispatch('getYhbhList').then(list => {
            if (list.length == 0) {
                this.$refs['loadingLayoutOuter'].setStatus('empty');
            } else {
                this.$refs['loadingLayoutOuter'].setStatus('success');
                this.yhbh = list[0].yhbh;
                this.yhJbxx = list[0]
                this.pageSetup();
            }
        }).catch(err => {
            this.$refs['loadingLayoutOuter'].setStatus('net-error');
        });
    },
}
</script>

<style lang="scss" scoped>
  
  $top-bg-color: #00b1ff;

  .page-sbjlcx /deep/ {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    .van-nav-bar {
      background-color:  $top-bg-color;
    }
    .van-nav-bar .van-icon {
      color: #fff;
    }
    .van-nav-bar__title {
      color: #fff;
    }
    .van-nav-bar__right {
      padding: 0;
    }
    
    .top {
      flex-basis: 152px;
      background-color: $top-bg-color;
      position: relative;
    }
    .top::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-image: url('/assets/images/home/city-shadow.png');
      background-position: bottom;
      background-size: auto 90%;
      background-repeat: no-repeat;
      opacity: 0.13;
    }

    .yh-zhxx {
      position: relative;
      z-index: 1;
      padding: 20px 40px;
      color: #fff;
      font-size: 14px;
      background: rgba(0,177, 255,0.68);
      dl {
        margin: 0;
        line-height: 26px;
      }
      dt, dd {
        display: inline-block;
      }
      dd {
        margin-left: 0;
      }
    }
  }  
</style>